@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,700,900&display=swap);
/* Global CSS */
body {
  background-color: #fff;
  color: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
}

ul {
  list-style-type: none;
}

li,
a {
  text-decoration: none;
  color: white;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  background-color: #fff;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  font-weight: 600;
  vertical-align: middle;
  cursor: pointer;
  color: #3b3d3b;
  text-shadow: 0 1px 1px hsla(0, 0%, 100%, 0.75);
  background-color: #cccecc;
  background-image: linear-gradient(180deg, #dadbd9, #b8bab7);
  background-repeat: repeat-x;
  background-origin: border-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.25), 0 2px 2px rgba(0, 0, 0, 0.17);
}

.btn-info {
  color: #ffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: #3368da;
  background-image: linear-gradient(180deg, #80c9ee, #007fbf);
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.25);
  background-origin: border-box;
}

/* End of Global CSS */

/* Navbar */

.navbar {
  display: flex;
  background-color: #1b262c;
}

.navbar-nav {
  display: flex;
  margin-left: auto;
}

.navbar-nav-dropdown {
  margin-right: 10px;
  padding-top: 5px;
  display: block;
  cursor: pointer;
}
/* .navbar-profile {
  background-color: teal;
} */

.navbar-nav-dropdown-list {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  margin-top: 40px;
  margin-left: -75px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navbar-nav-dropdown-list a {
  color: black;
  float: none;
  padding: 10px 5px;
  display: block;
}

.navbar-nav-dropdown-list a:hover {
  background-color: rgba(135, 135, 135, 0.3);
}

.navbar-nav-cart {
  flex: 3 1;
  margin: 5px 0;
  display: flex;
}

.navbar-nav-cart-btn {
  height: 35px;
  width: 75px;
  background-color: #3368da;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px 0 0 4px;
  vertical-align: middle;
  display: flex;
  margin: auto;
  justify-content: center;
  padding-top: 7px;
}
.navbar-nav-cart-btn:hover {
  cursor: pointer;
}

.cart-logo {
  height: 15px;
  width: 20px;
  margin-left: 5px;
}

.navbar-side-menu {
  width: 250px;
  background: rgb(82, 82, 82);
  height: 100%;
}

.open-slide {
  margin: auto 10px;
}

.navbar-basketcount {
  margin-right: 20px;
  background-color: white;
  font-size: 1.3rem;
  font-weight: bold;
  width: 50px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0 4px 4px 0;
}

.side-bar-list {
  font-size: 1.2rem;
  cursor: pointer;
  margin: 10px 0;
  padding-left: 12px;
  border-bottom: 1px solid rgb(141, 137, 137);
}

.side-bar-list:hover {
  background-color: rgba(135, 135, 135, 0.3);
}

.navbar-logo {
  margin-top: 10px;
  margin-left: 10px;
}

.navbar-container-logo {
  display: none;
}

/* End of Navbar */

/* Start of Sticky Banner */
/* .nav-banner {
  position: sticky;
} */

/* .nav-banner-scrolled {
  position: fixed;
  width: 100%;
  margin-left: auto;
  z-index: 100;
} */

/* End of Sticky Banner */

/* Start of Hero Section */
.hero-container-group {
  display: none;
  border: 1px solid rgba(135, 135, 135, 0.3);
}

/* .hero-category {
  display: none;
} */

/* .hero-carousel {
  flex: 1;
  width: 100%;
} */

.hero-carousel-wrapper {
  display: flex;
  flex-direction: column;
}

.hero-carousel-details {
  margin: auto;
  text-align: center;
  flex-wrap: wrap;
}

.hero-carousel-details-h1 {
  margin-top: 100px;
  font-size: 50px;
  color: whitesmoke;
  text-shadow: 5px 0 5px #000000;
}

.hero-carousel-details-p {
  margin: 20px 0 40px 0;
  color: whitesmoke;
  text-shadow: 2px 1px 3px #000000;
  font-size: 18px;
}

.hero-carousel-details-btn {
  cursor: pointer;
  height: 50px;
  width: 150px;
  background-color: #3368da;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  text-shadow: 1px 1px #000000;
  vertical-align: middle;
  margin: auto;
}

.hero-carousel-details-btn:hover {
  background-color: #0a41b8;
  transition: 0.3s;
}

polygon {
  fill: #0f4c81;
}

/* End of Hero Section */

.errorBackground {
  min-height: calc(100vh - 66px);
  background: url(/static/media/ErrorPage404.28eba6e8.jpg) center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.errorBackground h1 {
  font-size: 5em;
  font-weight: bold;
  text-align: center;
  width: 70%;
  color: #3d3d3d;
}

.errorBackground p {
  font-size: 1.5em;
  text-align: center;
  width: 65%;
}

/* Start of Search Header */
.banner-container {
  display: block;
  width: 100%;
  padding-left: 30px;
}

.global-banner-logo {
  display: none;
}

.global-search {
  width: 100%;
  margin: auto;
  padding: 10px 0;
}

.banner-search {
  width: 100%;
  position: relative;
}

.banner-search-icon {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  font-size: 15px;
  margin-left: 10px;
}

.svg-icon {
  padding: 2px 5px;
  width: 2.6rem;
}

.banner-search-group {
  box-sizing: border-box;
  padding-right: 90px;
  float: right;
  position: relative;
  width: 100%;
  margin-right: 20px;
}

.banner-search-input {
  width: 97.5%;
  border-radius: 5px 0 0 5px;
  border: 1px solid#aeb0ad;
  font-size: 20px;
  color: #3b3d3b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  height: 12px;
  margin-bottom: 0;
  vertical-align: middle;
  float: left;
  margin-left: 10px;
  padding: 15px 45px 18px;
  box-sizing: border-box;
}

.banner-search-btn {
  width: 100px;
  margin-right: -100px;
  font-size: 12px;
  padding: 8px 10px;
  margin-top: 0;
  margin-bottom: 10px;
  border-radius: 0 5px 5px 0;
  text-transform: capitalize;
}

/* End of Search Banner */

.awesomplete {
  position: static;
}

/* Start of Item View */
.item-views {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #fff;
  width: 95%;
}

.item-box-group {
  width: 200px;
  box-sizing: border-box;
  border: 1px solid #8e8e8f;
  /* border-bottom: 1px solid #dadbd9; */
  padding: 2%;
  margin-top: 2px;
  float: left;
  position: relative;
  display: flex;
  flex-direction: column;
}

/* .item-box-group:hover {
  width: 25%;
  height: 7%;
  z-index: 99;
} */

/* .item-view-box {
  background-color: violet;
} */
.on-sale {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(243, 8, 8, 0.8);
  color: var(--mainWhite);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 1rem;
  text-align: center;
  transition: var(--mainTransition);
}

.price-top h6 {
  margin-bottom: 0;
  /* font-size: 5rem; */
  font-weight: 900;
  letter-spacing: var(--mainSpacing);
}

.item-details-container {
  display: flex;
  flex-direction: column;
}

.img-container {
  display: flex;
  width: 85%;
  height: 120px;
  margin: auto;
}

#itemImg {
  margin: auto;
  width: auto;
  height: 100%;
  cursor: pointer;
}

.itemImghover:hover {
  /* width: 107%; */
  /* height: 110%; */
  transform: scale(1.2);
  transition: 0.3s;
}

.product-detail {
  text-align: center;
  margin: 8px 0;
}

.product-detail-desc {
  margin-bottom: 8px;
  color: #000;
  font-size: 0.6rem;
  font-weight: 700;
  height: 25px;
  /* text-shadow: 1px 1px 1px #4d4646; */
}

.sub-text {
  font-size: 10px;
  text-transform: uppercase;
  line-height: 15px;
  color: #aeb0ad;
  border-bottom: 1px solid #dadbd9;
}

.pricing {
  display: flex;
  justify-content: center;
}

.dollarSign {
  position: absolute;
  top: 200px;
  left: 50px;
  font-size: 1rem;
  font-weight: bold;
}

.dollarValue {
  font-size: 2rem;
  font-weight: 700;
}

.centsValue {
  font-size: 1rem;
  font-weight: bold;
}

.price {
  font-size: 20px;
  font-weight: bold;
  color: #3b3d3b;
}
.each {
  padding-left: 2px;
  font-size: 12px;
  color: #3b3d3b;
  font-weight: bold;
}

/* .cartAndQuantity {

} */

.add_qty {
  width: 30px;
  float: left;
  padding: 5px 0 8px;
  border-radius: 4px 0 0 4px;
  border-right: 0;
  position: absolute;
  height: 30px;
  text-align: center;
  font-weight: bold;
}

.btn-cart {
  /* font-size: 10px; */
  font-weight: 700;
  width: 100%;
  height: 30px;
  padding-left: 25px;
  color: #fff;
  background-color: #0054a6;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
}

.out-of-stock {
  background-color: #8f8e8f;
  cursor: default;
}

/* Checkout Order Review */
.faIcon {
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.order-summary-appbar {
  display: flex;
  justify-content: space-between;
  height: 40px;
  background-color: #8f8e8f;
  padding: 5px 5px;
  border-radius: 8px 8px 0 0;
}

.order-summary-appbar-sub {
  display: flex;
}

.cartlist-cotainer {
  background-color: #fff;
  padding: 0 10px;
}

.cartlist-header-title {
  display: none;
}

.cart-item-container {
  display: flex;
  flex-flow: column;
}

.cart-item-wrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: stretch;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 5px 0;
  border-bottom: 1px solid rgba(189, 189, 189, 0.5);
}

.cart-item-img-container {
  width: 20%;
  height: 100%;
  background: #fff;
}

.cart-item-img {
  height: 100%;
  width: 100%;
}

.cart-item-desc-container {
  margin: auto;
  width: 70%;
  /* padding-top: 10px;
  margin-left: 1vh;
   */
  /* margin-right: 0; */
  display: flex;
  flex-direction: column;
}

.cart-item-desc {
  color: #000;
  font-size: 0.7rem;
  font-weight: bold;
  width: 100%;
}

.cart-item-itemcode {
  color: #5a5a5a;
  font-size: 0.5rem;
  margin-top: 10px;
}

.cart-item-qty {
  margin: auto;
  /* width: 150px; */
}

.cart-item-qty input {
  width: 50px;
  height: 20px;
  text-align: center;
}

.cart-item-qty button {
  background-color: #dee3e2;
  color: #000;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 20px;
  height: 20px;
}
.cart-item-qty button:hover {
  cursor: pointer;
}
.cart-item-btn-left {
  border-radius: 5px 0 0 5px;
  /* margin-right: 3px; */
}
.cart-item-btn-right {
  border-radius: 0 5px 5px 0;
  /* margin-left: 3px; */
}

.cart-item-label-container {
  width: 40%;
  display: flex;
  justify-content: space-around;
  margin: auto;
}
.cart-item-label-total {
  font-weight: 500;
}

.cart-item-totalamnt {
  font-size: 1.5rem;
  font-weight: bold;
}

.cart-item-delete {
  display: flex;
  margin: auto;
}

/* Midle Conent Container */

/* Footer Page Area*/
/* .footer-page {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
} */

/* Header Group Content */
.headergroup-root {
  display: flex;
  flex-direction: column;
}

.headergroup-img-container {
  display: flex;
  margin: auto;
  width: 100%;
  height: 140px;
}

.headergroup-img {
  background-image: url(/static/media/header-bg-150px.cf9cb9f4.jpg);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.headergroup-logo {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 26px;
}

.headergroupstandard-btn-container {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 25px;
  margin: 0;
  padding: 0;
}

.headergroupstandard-link {
  cursor: pointer;
  font-size: 1.5rem;
  display: inline-block;
  position: relative;
}

.headergroupstandard-link::before {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.headergroupstandard-link:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.headergroupstandard-link-title {
  font-size: 0.8rem;
}

.headergroupstandard-icon {
  padding: 0 10px;
  font-size: 0.8rem;
}

.headergroup-form-container {
  position: absolute;
  top: 2px;
  right: 10px;
  height: 25px;
  background-color: #fff;
  border-radius: 25px;
  margin: 0;
  padding: 0;
}
.headergroup-form-center {
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 10px;
}
.headergroup-form {
  display: flex;
  margin: 'auto';
}

.headergroup-input {
  width: 80%;
  margin: auto;
  border: none;
}
.headergroup-submit-btn {
  background-color: #fff;
  border: none;
  color: #0054a6;
  cursor: pointer;
}

.headergroup-msgs {
  position: absolute;
  margin-top: 35px;
  text-align: center;
  color: #fff;
  width: 100%;
}
.headergroup-msg-title {
  font-weight: 700;
  text-shadow: 1px 1px 2px #000;
}

.headergroup-msg-subtitle {
  font-weight: 700;
  font-size: 0.8rem;
}
.headergroup-msg-text {
  padding: 0 30px;
  font-weight: 300;
  font-size: 0.5rem;
}
.headergroup-msg-tex {
  display: 'none';
}

/* Scroll Button */

.scroll-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

/* .scroll-to-top {
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
  animation: fadeIn 700ms ease-in-out 1s both;
} */

.scroll-container {
  background-color: #fff;
}

.scroll-arrow-container {
  background-color: #8f8e8f;
  padding: 5px;
  border-radius: 10px;
}

.scroll-icon {
  margin: auto;
  color: #fff;
  font-size: 2.5rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.phone-icon {
  font-size: 1.5rem;
  color: #fff;
  margin-top: auto;
  margin-bottom: auto;
}

/* End of Header Group Content */

.loader-center {
  margin: auto;
  width: 50%;
  max-width: 400px;
  min-width: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

/* Mobile View */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 600px) {
  .headergroupstandard-link-title {
    font-size: 1.3rem;
  }

  .headergroupstandard-icon {
    font-size: 1.5rem;
  }

  .cart-item-label-container {
    width: 22%;
  }

  .cart-item-qty input {
    height: 25px;
  }
  .cart-item-qty button {
    width: 30px;
    height: 25px;
  }

  .cart-item-desc-container {
    margin: auto;
    width: 35%;
    padding-top: 10px;
    margin-left: 1vh;
  }

  .cart-item-wrapper {
    flex-wrap: none;
  }

  .cart-item-img-container {
    width: 15%;
  }
  .headergroup-msgs {
    margin-top: 65px;
  }
  .headergroup-msg-title {
    font-size: 3rem;
  }

  .headergroup-msg-subtitle {
    font-size: 2rem;
  }

  .headergroup-msg-text {
    font-size: 1.15rem;
  }

  .headergroup-submit-btn {
    font-size: 1.3rem;
  }
  .headergroup-input {
    font-size: 1.3rem;
    width: 195px;
  }
  .headergroup-input :focus {
    width: 250px;
  }

  .headergroup-form {
    margin-left: 15px;
  }

  .headergroup-form-container {
    height: 45px;
    width: 250px;
  }

  .headergroup-form-container:focus {
    width: 300px;
    transition: width 250ms ease-in-out;
  }

  .headergroup-logo {
    display: flex;
    height: 50px;
    border-radius: 15px;
  }

  .headergroup-img-container {
    height: 300px;
  }
  .headergroup-img {
    background-image: url(/static/media/header-bg-350px.0ff3ed3d.jpg);
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
  .headergroup-msg-tex {
    display: flex;
  }

  .navbar-nav-dropdown {
    display: none;
  }
  .navbar-container-logo {
    display: block;
  }
}

/* Table devices */
@media (min-width: 800px) {
  .open-slide {
    display: none;
  }
  .hero-container-group {
    display: flex;
    width: 95%;
    border: 1px solid rgba(135, 135, 135, 0.3);
  }
  /* .hero-category {
    display: flex;
    width: 200px;
    height: 100%;
    margin-right: 5px;
    flex-direction: column;
  } */

  .hero-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    background-color: #084177;
    border-radius: 15px 15px 0 0;
    color: whitesmoke;
  }

  .hero-menu-list {
    background-color: #fff;
    height: 100%;
  }

  .hero-menu-list li {
    color: black;
    font-size: 1.2rem;
    margin: 10px 0;
    padding-left: 15px;
    cursor: pointer;
    border-bottom: 2px solid #3b3d3b;
  }

  .hero-menu-list li:hover {
    background-color: rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }

  .hero-menu-list a {
    color: black;
  }

  .hero-carousel {
    width: 100%;
    flex: 4 1;
  }

  .cartlist-header-title {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-top: 8px;
    font-size: 0.5rem;
    border-bottom: 1px solid #dadbd9;
  }
  .cartlist-header-title-item {
    width: 50%;
  }
  .cartlist-header-title-qty {
    width: 10%;
  }
  .cartlist-header-title-price {
    width: 10%;
  }
  .cartlist-header-title-total {
    width: 10%;
    margin-right: 10px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .navbar-container-logo {
    display: none;
  }

  .banner-container {
    width: 95%;
    display: flex;
    margin: 5px 0;
    padding: 0px;
  }
  .global-banner-logo {
    display: flex;
  }

  .banner-logo {
    float: left;
    width: 325px;
    height: 60px;
    padding-right: 20px;
    margin-right: 50px;
  }

  .banner-search-group {
    width: 100%;
    margin-top: 5px;
    padding-right: 75px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

